$bodyFont: 'Montserrat', sans-serif;
$headingFont: 'Baskervville', serif;
$purple: #7700FF; 
$black: #23262F; 
$yellow: #FFF275; 
$milkWhite: #F9F8FF;

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

body {
    font-family: $bodyFont;   
    color: $black; 
    background: $milkWhite;
    @media all and (min-width: 599px) {
        font-size: 18px;
    }
}

h1,
h2,
h3 {
    font-family: $headingFont;    
}

h2 {
    font-size: 2rem;
    @media all and (min-width: 599px) {
        font-size: 2.5rem;    
    }
    @media all and (min-width: 899px) {
        font-size: 3rem;    
    } 
}

p {
    line-height: 1.75;    
}

.row {
    max-width: 2400px;  
}

.container {
    max-width: 1500px;    
}

.yellow {
    color: $yellow;
    font-family: $headingFont;    
}

.normal-flow {
    @media all and (min-width: 799px) {
        flex-direction: row !important;
    }
}

.reverse {
    @media all and (min-width: 799px) {
        flex-direction: row-reverse !important;
    }
}

.sec-font {
    font-family: $headingFont;    
    font-size: 1.25rem;
    @media all and (min-width: 799px) {
        font-size: 1.75rem;
    }
}

.center-text {
    text-align: center;    
}

.lg-text {
    font-size: 3rem;    
}

#mobileMenu {
    position: relative;
    text-align: width;
    width: 60px;
    margin: -30px auto 0;   
    z-index: 9; 
    @media all and (min-width: 499px) {
        position: absolute;
        top: 44px;
        right: 12vw;    
    }
}

#mobileMenu-button {
  padding: 26px 11px;
  cursor: pointer;
  user-select: none;
  span {
      height: 4px;
      width: 35px;
      border-radius: 2px;
      background-color: $black;
      position: relative;
      display: block;
      transition: all .2s ease-in-out;
      &:before {
        top: -10px;
        visibility: visible;
      }
      &:after {
        top: 10px;
      }
      &:before, &:after {
          height: 4px;
          width: 35px;
          border-radius: 2px;
          background-color: $black;
          position: absolute;
          content: "";
          transition: all .2s ease-in-out;
      }
    }
    &:hover span, &:hover span:before, &:hover span:after {
      background: #333332;
    }
}

input[type=checkbox] {
  display: none;
}

input[type=checkbox]:checked ~ nav {
  visibility: visible;
}

input[type=checkbox]:checked ~ #mobileMenu-button {
  &:hover span, span{
    background: transparent;
  }
  span {
    &:before {
      transform: rotate(45deg) translate(7px, 7px);
      opacity: 1;
    }
    &:after {
      transform: rotate(-45deg) translate(7px, -7px);
    }
  }
}

nav {
  height: 0;
  width: 100vw;
  background: $milkWhite;
  z-index: 2;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transition: height 400ms;
  &.active {
    visibility: visible;
    height: 100vh;
  }
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100vh;
    padding-left: 0;
    list-style-type: none;
    li {
      padding: 1em;
      a {
        color: white;
        text-decoration: none;
        font-size: 1.5rem;
        @media all and (min-width: 768px) {
            font-size: 2.5rem;    
        }
        &:hover {
          color: #333332;
        }
      }
    }
  }
}

.nav-link {
    color: $black;  
    &:hover {
        color: $purple;    
    }  
}

.white-text-bg {
    color: $milkWhite;
    background-color: rgba(black, 0.6);
    border-radius: 10px;
    padding: 40vw 12vw;
    @media all and (min-width: 699px) {
        padding: 20vw 12vw;    
    }
    @media all and (min-width: 899px) {
        padding: 10vw 12vw;    
    }
}

.home-content,
.inside-content {
    text-align: center;
    margin: 20vw 20px;
    flex-direction: column;
    @media all and (min-width: 599px) {
        margin: 16vw 8vw;    
    }
    @media all and (min-width: 799px) {
        flex-direction: row;
        text-align: left;
        margin: 20vw auto;
        max-width: 90%;
        font-size: 1.25rem;
        &:nth-of-type(even) {
            flex-direction: row-reverse;
        }   
    }
    @media all and (min-width: 1099px) {
        margin: 10vw auto; 
        max-width: 1500px; 
    }
    & .col {
        &:last-child {
            order: 1;
        }   
        & .text-block {
            max-width: 520px;
            margin: auto;
            &.about-col {
                max-width: 615px;
                background: $black;
                padding: 20px 30px;
                border-radius: 20px;   
                color: $milkWhite; 
                @media all and (min-width: 799px) {
                    margin-left: -100px;
                }
                & p {
                    margin: 0;    
                }
            }
        } 
    }
    & img {
        width: 300px;
        display: block;    
        margin: 40px auto;
        @media all and (min-width: 799px) {
            width: 36vw;  
            max-width: 700px;
        }
    }  
}

.inside-content {
    & .history {
        padding: 0 7vw; 
    }
    & img {
        width: 100%;
        @media all and (min-width: 799px) {
            width: 46vw; 
        }
    }
    & .col .text-block {
        max-width: 700px;
    }
}

#grocery {
    @media all and (min-width: 1499px) {
        width: 1000px !important;    
        max-width: none !important;
    }    
}

.btn {
    background-color: $purple;
    border: 1px solid $purple;
    border-radius: 10px;
    color: $milkWhite;
    display: inline-block;
    width: auto;
    font-size: 1.25rem;
    &:hover {
        background-color: $milkWhite;
        color: $purple; 
    }
}
    
.wrapper {
    max-width: 1800px;
    margin: 0 auto;    
    overflow: hidden;
}

header {
    justify-content: center;
    @media all and (min-width: 499px) {
        justify-content: flex-start;    
    }
}

.header-wrap {
    background-image: url(../svg/spilled-milk.svg);    
    background-size: 290%;
    background-position: -62vw -58vw;
    background-repeat: no-repeat;
    padding-bottom: 20vw;
    position: relative;
    z-index: 2;
    margin-bottom: -52vw;
    @media all and (min-width: 499px) {
        background-size: 260%;
    }
    @media all and (min-width: 599px) {
        background-size: 240%;
        background-position: -56vw -60vw;
    }
    @media all and (min-width: 799px) {
        background-position: -56vw -66vw;
        margin-bottom: -36vw;
    }
    @media all and (min-width: 899px) {
        background-position: -56vw -70vw;
        margin-bottom: -38vw;
    }
    @media all and (min-width: 1400px) {
        background-size: 2400px;
        background-position: -410px -580px;
        margin-bottom: -42vw;
    }
    @media all and (min-width: 1799px) {
        background-position: -400px -600px;
    }
    & header > a {
        padding-top: 10px;  
        @media all and (min-width: 499px) {
            margin-left: 7vw;  
        }
        & svg {
            width: 280px;
            position: relative;
            z-index: 3;
            @media all and (min-width: 899px) {
                width: 360px;
            }
        }
    }
    & header {
        @media all and (max-width: 1399px) {
            padding: 0 4vw;    
        }    
    }
    & > div {
        max-width: 1420px;
        margin: auto;
        position: relative;
    }
}

.hero {
    position: relative;
    z-index: 1;
    color: $milkWhite;
    border-bottom: 1px solid $black;
    & .hero-text {
        position: absolute; 
        width: 300px;
        top: 35%;
        text-align: center;
        left: 50%;
        margin-left: -150px;
        @media all and (min-width: 499px) {
            width: 450px;
            margin-left: -225px;
        }
        @media all and (min-width: 767px) {
            text-align: left;
            left: 33%;
            top: 38%;    
        }
        @media all and (min-width: 1099px) {
            top: 54%;   
            width: 485px; 
        }
        @media all and (min-width: 1299px) {
            top: 60%;   
            width: 485px; 
        }
        & h1 {
            font-size: 2.5rem;
            @media all and (min-width: 499px) {
                font-size: 3rem;
            } 
            @media all and (min-width: 1099px) {
                font-size: 3.5rem;
            } 
        }
        & span {
            font-size: 1.25rem;
            font-weight: 100;
            @media all and (min-width: 499px) {
                font-size: 1.75rem;
            }
            @media all and (min-width: 1099px) {
                font-size: 2rem;
            } 
        }
        & .btn {
            margin-top: 20px;    
        }
    }
}

.inside-hero {
    & .row {
        margin: auto;  
        max-width: 1200px;  
    }
    & img {
        @media all and (min-width: 799px) {
            max-height: 64vw;
        }
    }
}

.call-us {
    width: 240px;
    background: $milkWhite;
    border-radius: 20px;
    padding: 10px 0;
    text-align: center;
    margin: -18px auto 0;
    position: relative;
    z-index: 9;    
    font-family: $headingFont;
    color: $black;
    border-top: 2px solid $black;
    border-bottom: 2px solid $black;
    @media all and (min-width: 499px) {
        width: 320px; 
    }  
    & span {
        display: block;
        font-size: 1rem;    
    }
    & a {
        font-size: 2rem;
        color: $black;
        text-decoration: none; 
        @media all and (min-width: 499px) {
            font-size: 2.5rem;    
        }   
    }
}

.maebell {
    background-image: url(../images/maebell.png);    
    background-size: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    padding-top: 20vw;
    margin: 20vw 8vw -14vw;
    text-align: center;
    & p:last-child {
        padding-bottom: 54vw;    
        @media all and (min-width: 1600px) {
            padding-bottom: 900px;    
        }
    }
    @media all and (min-width: 768px) {
        & > div {
            max-width: 90%;
            margin: auto;
            & h2 {
                font-size: 3rem;
                text-align: left;    
            }
            & p {
                font-size: 1.75rem;    
                margin-left: 20vw;
            }
        }
    }
    & > div {
        @media all and (min-width: 999px) {
            max-width: 80%;
            & h2 {
                font-size: 3.5rem;    
            }    
        }    
    }
    @media all and (min-width: 999px) {
        padding-top: 5vw;   
        margin-top: 5vw; 
    }
}

.snacks {
    background-image: url(../images/snacks-bg.jpg);    
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    text-align: center;
    & .white-text-bg {
        @media all and (min-width: 599px) {
            font-size: 1.5rem;    
        }
    }
    & h3 {
        margin-bottom: 15px;   
        font-size: 1.75rem; 
        @media all and (min-width: 599px) {
            font-size: 3rem;    
        }
    }
    & p {
        @media all and (min-width: 499px) {
            padding: 6vw 0;
            line-height: 2;    
        }    
    }
    & .btn {
        width: auto;
        margin: auto;    
    }
}

.platters {
    margin: 0;
    overflow: hidden;
    position: relative;
    padding: 40vw 20px 0;
    @media all and (min-width: 599px) {
        padding-top: 30vw;    
        font-size: 1.5rem;
        & .col {
            max-width: 70%; 
            margin: auto;
        }
    }
    @media all and (min-width: 1099px) {
        padding: 7vw;  
        background-image: url(../images/party-platter.png);
        background-size: 60%;
        background-position: right;
        background-repeat: no-repeat;
        & .col {
            max-width: 46%; 
            margin: 0;
        }
        & .spinning-platter {
            display: none;    
        }
    }
    @media all and (min-width: 1299px) {
        padding: 140px 100px;   
        & .col {
            max-width: 600px;    
        } 
    } 
    & h2 {
       @media all and (min-width: 799px) {
           font-size: 2.5rem;
        }     
    }
}

.spinning-platter {
    background-image: url(../images/meat-and-cheese-platter.png);    
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30vw 0 64vw;
    animation: rotate 180s infinite linear;
    transform-origin: 50%;
    position: relative;
    z-index: 9;
    bottom: -40vw;
    @media all and (min-width: 499px) {
        margin-bottom: -16vw;   
        bottom: -26vw;
    }
}  

footer {
    background-image: url(../svg/footer-bg.svg);
    background-size: 142%;
    background-position: 100% 100%;
    background-repeat: no-repeat;  
    color: $milkWhite;
    padding: 10vw 10vw 30vw;
    background-color: $black;
    font-size: 0.8rem;
    & .lg-text {
        font-size: 2rem;  
        @media all and (min-width: 799px) {
            font-size: 3rem;    
        }  
    }
    @media all and (min-width: 799px) {
        font-size: 1.5rem; 
        background-size: 100%;
        padding: 10vw 7vw 30vw;
        & h3 {
            font-size: 2.25rem;    
        }  
    }
    @media all and (min-width: 1299px) {
        padding: 5vw 7vw 16vw;
    }
    & p:first-of-type {
        margin: 0;
    }
    & .copyright {
        font-size: 0.8rem;
        margin-top: 100px;    
    }
    & a {
        color: white;
        text-decoration: none;
        margin: 0;
        display: inline-block;
        &:hover {
            text-decoration: underline;    
            color: white;
        }
        & img {
            width: 25px;    
            display: block;
            transition: all 400ms;
            @media all and (min-width: 799px) {
                width: 50px;    
            }
        }
        &:hover img {
            transform: scale(1.1);    
        }
    }
    & h3 {
        color: $yellow;
        font-size: 1.75rem;
        margin: 0;
        & a {
            color: $yellow;    
        }
    }
    & #lwdc {
        font-size: 0.6rem;    
    }
}


/* -- CONFETTI START -- */

.confetti-wrapper {
    position: relative;
    min-height: 100%;
    top: 0;
    left: 0;
    padding: 0;
    width: 100%;
}

[class|="confetti"] {
  position: absolute;
  top: 0;
  z-index: -1;
}

$colors: (#d13447, #ffbf00, #263672);

@for $i from 0 through 150 {
  $w: random(8);
  $l: random(100);
  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w*0.4}px;
    background-color: nth($colors, random(3));
    top: -10%;
    left: unquote($l+"%");
    opacity: random() + 0.5;
    transform: rotate(#{random()*360}deg);
    animation: drop-#{$i} unquote(4+random()+"s") unquote(random()+"s") infinite;
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l+random(15)+"%");
    }
  }
}

/* -- CONFETTI END -- */


/* -- INSIDE START -- */

.page-title {
    position: relative;
    & h1 {
        font-size: 4rem; 
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        @media all and (min-width: 799px) {
            width: auto;
            text-align: left;
            font-size: 5rem;   
            padding-left: 40px; 
        }
    }
}

.padding {
    padding: 20vw 40px 16vw;
    @media all and (min-width: 899px) {
        padding: 200px 40px;    
    }
    & h2 {
        font-size: 1.25rem;
        @media all and (min-width: 699px) {
            font-size: 2.75rem;
        }    
    }
    & .lg-text {
        font-size: 3rem;
    }    
    & a.lg-text {
        color: $black;
        border-bottom: 2px solid $purple;
        text-decoration: none;
        &:hover {
            color: $purple;    
        }    
    }
}

.dark-bg {
    background-color: $black;
    color: $milkWhite;
    padding: 8vw;
    @media all and (min-width: 1299px) {
        padding: 60px;    
    }
    & .row {
        flex-direction: column; 
        @media all and (min-width: 899px) {
            flex-direction: row;    
            & .col {
                margin: auto;
            }
        }   
        & .col {
            margin: 60px auto;    
        }
    }
    & h3 {
        font-size: 2rem;    
    }
    & h3,
    & h4 {
        color: $yellow;    
    }    
    & .col > div {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;    
        }    
    }
}

.dine-in {
    background-image: url(../images/dine-in.jpg);
    background-size: cover;
    background-position: 0 80%;
    color: $milkWhite;
    & .padding {
        background-color: rgba(black, 0.8);    
        & h2 {
            font-size: 2.25rem;    
        }
    }
    & h3 {
        font-size: 1.75rem;    
    }
    & p {
        margin-bottom: 0; 
    }   
}


#contactForm {
    & iframe {
        max-width: 500px;    
        background-color: white;
        padding: 8px;
        border-radius: 20px;    
    }
}

.toluca {
    background-image: url(../images/toluca.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: #FDFDFD;
    line-height: 2;    
    @media all and (min-width: 799px) {
        font-size: 1.25rem;    
    }
    & > div {
        width: 80%;
        max-width: 800px;
        margin: auto;
        text-align: center;
        padding: 20vw 0 50vw;    
        & h2,
        & p {
            margin-bottom: 40px;    
        }
    }
}

.about-title {
    padding: 0 10vw 0;
    margin-bottom: -16vw;    
    @media all and (min-width: 799px) {
        margin-bottom: -4vw;    
    }
    &:first-child {
        padding-top: 12vw;
    }
}

.cow-bg {
    background-image: url(../images/cow-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #FDFDFD;
    margin-bottom: -10px;
    @media all and (min-width: 799px) {
        background-position: bottom;        
    }
    & > div:last-child {
        margin-bottom: 0;    
    }
}

.fried-chicken h3 {
    font-size: 1.25rem;    
}

.fried-chicken .col.mt-4 ul li {
    margin-top: 8px !important;
    font-size: 1rem;    
}

#breakfast,
#lunch,
#deli, 
#snacks,
#platters,
#dessert {
    padding: 8vw 0 10vw;
    @media all and (min-width: 1099px) {
        padding: 60px 0;
    }
    & .row {
        flex-direction: column;
        max-width: 1200px;
        margin: auto;
        @media all and (min-width: 799px) {
            flex-direction: row;    
        }
        & .col {
            padding: 25px 40px;
            & .col {
                padding: 0 0 20px;    
            }    
        }
        & ul {
            list-style-type: none;  
            margin: 0;
            padding: 0;
            & li:not(.subitem) {
                margin-top: 15px;
            }  
        }
    }    
    & .subitem {
        font-size: 0.8rem;  
        max-width: 340px;
    }
    & .price-sep {
        margin: 0 5px;    
    }
    & h3 {
        font-family: $bodyFont;    
        margin: 0;
    }
    & h2 {
        margin-bottom: 0;    
    }
    & h2 + span,
    & h3 + span {
        font-size: 0.9rem;
        margin-left: 3px;
    }
}

#breakfast {
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: right 34%;
    @media all and (min-width: 1099px) {
        background-image: url(../images/breakfast-bg.jpg);
        padding-bottom: 80px;
    }    
}

#lunch {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right 33%;
    @media all and (min-width: 1099px) {
        background-image: url(../images/lunch-bg.jpg);
        padding-bottom: 80px;
    }
    & .row .col h3 + span {
        max-width: 420px;
        display: inline-block;
    }
}


#deli {
    color: $milkWhite;
    background-color: $black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 86% 0;
    padding-bottom: 8vw;
    @media all and (min-width: 1099px) {
        background-image: url(../images/deli-bg.jpg);
        padding-bottom: 80px;
    }
    & h3 {
        color: $yellow;
        & + span {
            color: $yellow;    
        }    
    }    
    & .row ul li:not(.subitem) {
        margin-top: 5px;    
        font-size: 1rem;
    }
}

#platters {
    background-size: 55%;
    background-repeat: no-repeat;
    background-position: 155% 95%;
    @media all and (min-width: 1099px) {
        background-image: url(../images/party-platter.png);
        padding-bottom: 200px;
    }     
}

#snacks,
#dessert {
    & .row {
        max-width: 1000px;    
    }    
}

#snacks {
    margin: 10vw auto;
    @media all and (min-width: 1099px) {
        margin: 60px auto;    
    }    
}

#dessert {
    background-image: url(../images/dessert-bg.jpg);
    background-size: 100%;
    background-position: bottom;
    padding-bottom: 200px;    
    padding-bottom: 60vw;
    background-repeat: no-repeat;
}


/* -- INSIDE END -- */